import {useLocation} from "react-router-dom";
import GuestFooter from "../Components/GuestFooter";
import MainFooter from "../Components/MainFooter";
import GuestHeader from "../Components/GuestHeader";
import {ReactNode} from "react";

export default function GuestLayout(props: { children: ReactNode }) {
    let location = useLocation();
    return (
        <>
            <GuestHeader />
            <div className=" mx-auto">
                {/* <div className="container bg-gradient-to-br from-[#FFFFFF]/100 via-[#F2F6FF]/50 to-[#E5ECFA]/100 border-inherit rounded-xl shadow-2xl shadow-indigo-500/50 mx-auto mt-10 mb-20  px-4 py-6 lg:px-20  w-auto"> */}
                {props.children}
                {/* </div> */}
            </div>
            {location.pathname === "/" ||
            "/account/auth/signup" ||
            "/account/auth/login" ? (
                <GuestFooter />
            ) : (
                <MainFooter />
            )}
        </>
    );
}
