import {useLocation} from "react-router-dom";
import {ReactNode} from "react";
import GuestHeader from "../Components/GuestHeader";
import MainFooter from "../Components/MainFooter";
import AccountBreadCrumb from "../Components/AccountBreadCrumb";
import GuestBreadCrumb from "../Components/GuestBreadCrumb";

export default function Layout(props: { children: ReactNode }) {
    const location = useLocation();
    return (
        <>
            <GuestHeader />

            {location.pathname === "/home" ? (
                <AccountBreadCrumb />
            ) : (
                <GuestBreadCrumb />
            )}
            <div className="bg-[#E4ECFB] shadow-inner">
                {/* <div className="container bg-gradient-to-br from-[#FFFFFF]/100 via-[#F2F6FF]/50 to-[#E5ECFA]/100 border-inherit rounded-xl shadow-2xl shadow-indigo-500/50 mx-auto mt-10 mb-20  px-4 py-6 lg:px-20  w-auto"> */}
                {props.children}
                {/* </div> */}
            </div>
            <MainFooter />
        </>
    );
}
