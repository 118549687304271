import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Install: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Links for redirection
    const appleStoreLink = 'https://apps.apple.com/app/idYourAppId';  // replace with your App Store link
    const googlePlayLink = 'https://play.google.com/store/apps/details?id=com.tefzon.mobile'; // replace with your Play Store link

    // Detect Apple devices
    const isAppleDevice = /iPad|iPhone|iPod|Mac/i.test(navigator.userAgent);
    
    if (isAppleDevice) {
      window.location.href = appleStoreLink;
    } else {
      window.location.href = googlePlayLink;
    }
  }, [navigate]);

  return null; // No content as it just redirects
};

export default Install;
